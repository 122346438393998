<script setup lang="ts">
import { ScreeningAction, ScreeningType } from '@factoryfixinc/ats-interfaces';
import { computed, onMounted, ref } from 'vue';
import { useDisplay } from 'vuetify';

import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';

import FormSection from './FormSection.vue';
import FFLogoSvg from '@/assets/svg/jobs/ff-logo.svg';
import SyncJobSvg from '@/assets/svg/jobs/sync-job.svg';
import { isRequired } from '@/utils/forms';
import MessageTemplate from '@/components/Shared/Input/Template/MessageTemplate.vue';
import ScreeningTypeOptions from './ScreeningTypeOptions.vue';
import { EmployerFeatureService } from '@/core/shared/employer-feature/employer-feature.service';
import ConfirmScreeningTypeChange from './ConfirmScreeningTypeChange.vue';
import ProjectService from '@/core/shared/project/project.service';

const display = useDisplay();
const copilotActivationService = new CopilotActivationService();
const projectService = new ProjectService();
const employerFeatureService = new EmployerFeatureService();
const isDoingZeroScreening = computed(() => employerFeatureService.hasZeroScreeningEnabled);
const hasUrlScreening = computed(() => employerFeatureService.hasUrlScreeningEnabled);
const openScreeningTypeDialog = ref(false);
const hasShownScreeningTypeDialog = ref(false);
const pendingScreeningActionConfirm = ref<ScreeningAction | null>();
const hasScreenerQuestions = computed(() => {
  return (
    !isDoingZeroScreening.value &&
    (!hasUrlScreening.value ||
      (hasUrlScreening.value && selectedAction.value === ScreeningAction.SCREEN))
  );
});

defineProps<{
  isDisabled: boolean;
}>();

const selectedAction = computed<ScreeningAction | null>(
  () => copilotActivationService.screeningAction || ScreeningAction.SCREEN,
);
const isDesktop = computed(() => display.mdAndUp.value);
const isScreenSelected = computed(() => selectedAction.value === ScreeningAction.SCREEN);
const isUrlSelected = computed(() => selectedAction.value === ScreeningAction.URL);
const isDisplayingCareerPageUrl = computed(() => selectedAction.value === ScreeningAction.URL);

function selectNewScreeningAction(action: ScreeningAction) {
  const copilotEnabled = projectService.currentProject?.copilot;
  if (
    !hasShownScreeningTypeDialog.value &&
    copilotEnabled &&
    copilotActivationService.jobUsesDynamicScreening
  ) {
    openScreeningTypeDialog.value = true;
    hasShownScreeningTypeDialog.value = true;
    pendingScreeningActionConfirm.value = action;
    return;
  }
  setScreeningAction(action);
  pendingScreeningActionConfirm.value = null;
}

function setScreeningAction(action: ScreeningAction) {
  copilotActivationService.screeningAction = action;
}

function savePendingScreeningAction() {
  if (pendingScreeningActionConfirm.value) {
    setScreeningAction(pendingScreeningActionConfirm.value);
    pendingScreeningActionConfirm.value = null;
  }
  openScreeningTypeDialog.value = false;
}

function cancelPendingScreeningAction() {
  pendingScreeningActionConfirm.value = null;
  openScreeningTypeDialog.value = false;
}

onMounted(() => {
  if (!employerFeatureService.hasDynamicScreeningEnabled) {
    copilotActivationService.screeningType = ScreeningType.STATIC;
  }
});
</script>

<template>
  <FormSection
    id="screening-questions"
    :title="hasUrlScreening ? 'What should Copilot do with candidates after they apply?' : ''"
    class="border-b-0"
    :add-divider="false"
  >
    <div class="mb-12 flex flex-wrap" v-if="hasUrlScreening">
      <!-- FF -->
      <div
        :class="{
          'mb-0 w-1/2': isDesktop,
          'mb-4 w-full': !isDesktop,
          'pointer-events-none opacity-75': isDisabled,
        }"
      >
        <div
          class="box-border cursor-pointer rounded-2xl border border-tint-100 p-4"
          :class="{
            'mr-2 min-h-[252px]': isDesktop,
            'mr-0 min-h-[250px]': !isDesktop,
            '!border-highlight-500 bg-highlight-0': isScreenSelected,
          }"
          @click="selectNewScreeningAction(ScreeningAction.SCREEN)"
        >
          <figure class="relative mb-4 h-12">
            <img :src="FFLogoSvg" alt="FF Logo" />
            <span
              class="absolute right-0 top-0 h-[18px] rounded bg-highlight-500 p-1 text-[10px] font-bold leading-[10px] text-white"
            >
              RECOMMENDED
            </span>
          </figure>
          <p class="mb-2 font-sans text-base font-bold leading-6">Screen each candidate</p>
          <p class="font-sans text-sm font-normal leading-6">
            Copilot will automatically send screening questions to applicants after they apply.
            Responses will be processed within FactoryFix.
          </p>
        </div>
      </div>
      <!-- Carrers page -->
      <div
        :class="{
          'min-h-[252px] w-1/2': isDesktop,
          'min-h-[250px] w-full': !isDesktop,
          'pointer-events-none opacity-75': isDisabled,
        }"
      >
        <div
          class="box-border min-h-full cursor-pointer rounded-2xl border border-tint-100 p-4"
          :class="{
            'ml-2 ': isDesktop,
            'mr-0': !isDesktop,
            '!border-highlight-500 bg-highlight-0': isUrlSelected,
          }"
          @click="selectNewScreeningAction(ScreeningAction.URL)"
        >
          <figure class="relative mb-4 h-12">
            <img :src="SyncJobSvg" alt="FF Logo" />
          </figure>
          <p class="mb-2 font-sans text-base font-bold leading-6">
            Redirect them to your careers page
          </p>
          <p class="font-sans text-sm font-normal leading-6">
            Copilot will notify applicants via text/email to apply directly on your careers page.
            Note: Applications won't sync with your ATS, and some candidates may not complete the
            process.
          </p>
        </div>
      </div>
    </div>

    <ScreeningTypeOptions
      v-if="hasScreenerQuestions && employerFeatureService.hasDynamicScreeningEnabled"
      class="mb-12"
    />

    <div v-if="isDisplayingCareerPageUrl">
      <p class="mb-0.5 mt-3 font-sans text-xs font-bold leading-[18px] text-shade-880">
        Careers page URL*
      </p>
      <v-text-field
        v-model="copilotActivationService.employerCareerSiteUrl"
        placeholder="Enter the URL for your careers page"
        variant="outlined"
        density="compact"
        :rules="[isRequired]"
        :disabled="isDisabled"
      >
        <template #message="{ message }">
          <MessageTemplate :message="message" />
        </template>
      </v-text-field>
    </div>
    <ConfirmScreeningTypeChange
      @save="savePendingScreeningAction"
      @cancel="cancelPendingScreeningAction"
      :is-open="openScreeningTypeDialog"
      :is-loading="false"
      v-if="openScreeningTypeDialog"
      confirm-action="Switch to careers page"
    />
  </FormSection>
</template>

<style lang="scss" scoped>
.v-input :deep(.v-field),
.v-input--horizontal :deep(.v-field) {
  border-radius: 6px;

  .v-field__outline {
    @apply text-tint-80;
  }
  input {
    @apply font-sans text-sm font-normal leading-[21px] text-shade-880;
  }
  input::placeholder {
    @apply text-shade-800;
    opacity: 1;
  }

  &:hover {
    .v-field__outline {
      @apply text-shade-840;
    }
  }
}

.v-input :deep(.v-field.v-field--focused),
.v-input--horizontal :deep(.v-field.v-field--focused) {
  .v-field__outline {
    @apply text-highlight-500;
  }
}
</style>
