<script lang="ts" setup>
import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';
import { ScreeningType } from '@factoryfixinc/ats-interfaces';
import ConfirmScreeningTypeChange from './ConfirmScreeningTypeChange.vue';
import { ref } from 'vue';
import ProjectService from '@/core/shared/project/project.service';

const activationService = new CopilotActivationService();
const projectService = new ProjectService();
const { STATIC, DYNAMIC } = ScreeningType;

const openScreeningTypeDialog = ref(false);
const hasShownScreeningTypeDialog = ref(false);

function selectScreeningType(newScreeningType: ScreeningType) {
  const copilotEnabled = projectService.currentProject?.copilot;

  if (
    copilotEnabled &&
    !hasShownScreeningTypeDialog.value &&
    newScreeningType === STATIC &&
    activationService.screeningType === DYNAMIC
  ) {
    openScreeningTypeDialog.value = true;
    hasShownScreeningTypeDialog.value = true;
    return;
  }
  setScreeningType(newScreeningType);
}

function setScreeningType(newScreeningType: ScreeningType) {
  activationService.screeningType = newScreeningType;
  openScreeningTypeDialog.value = false;
}

function cancelPendingScreeningAction() {
  openScreeningTypeDialog.value = false;
}
</script>

<template>
  <div>
    <div class="mb-4 text-lg font-extrabold">
      How would you like Copilot to screen candidates for this position?
    </div>
    <div class="flex flex-row gap-3">
      <div
        class="cursor-pointer rounded-2xl border border-tint-300 p-4"
        :class="{
          '!border-highlight-500 bg-highlight-0': activationService.screeningType === STATIC,
          'text-shade-820': activationService.screeningType !== STATIC,
        }"
        @click="selectScreeningType(STATIC)"
      >
        <span class="font-bold">Classic:</span>
        <span class="text-sm">
          Robotically ask a list of preset questions, and add nothing further
        </span>
      </div>
      <div
        class="flex cursor-pointer flex-row rounded-2xl border border-tint-300 p-4"
        :class="{
          '!border-highlight-500 bg-highlight-0': activationService.screeningType === DYNAMIC,
          'text-shade-820': activationService.screeningType !== DYNAMIC,
        }"
        @click="selectScreeningType(DYNAMIC)"
      >
        <div>
          <span class="font-bold">Dynamic:</span>
          <span class="text-sm">
            Interview naturally—ask targeted questions and adapt as needed
          </span>
        </div>
        <div>
          <div class="rounded bg-shade-900 p-1 text-[10px] font-bold text-tint-0">BETA</div>
        </div>
      </div>
    </div>
    <ConfirmScreeningTypeChange
      @save="setScreeningType(STATIC)"
      @cancel="cancelPendingScreeningAction"
      :is-open="openScreeningTypeDialog"
      :is-loading="false"
      v-if="openScreeningTypeDialog"
      confirm-action="Switch to preset questions"
    />
  </div>
</template>
